:root {
  --primary_color_1: #ffce21;
  --primary_color_2: #ffe687;
  --secondary_color_1: #59656f;
}
html {
  scroll-behavior: smooth;
}
body {
  top: 0 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbfbfb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.text-orange {
  color: #ff7a30;
}
.text-green {
  color: #188038;
}
.text-golden {
  color: #ffc950;
}
.bg-saturated-shade {
  background-color: #ff431a;
}

.skiptranslate iframe {
  display: none;
}

.content-ellipsis {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  max-height: 60px;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}
