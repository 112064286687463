.header {
  background-color: white !important;
}
.menu_item {
  text-transform: uppercase;
  font-weight: 400;
  font-size: medium;
  color: black;
  margin-left: 2rem;
  &:global(.active) {
    color: var(--primary_color_1) !important;
    border-bottom: solid 0.2rem var(--primary_color_1);
  }
  &:hover {
    color: var(--primary_color_1) !important;
  }
}

.menu_language {
  :global(.dropdown-menu) {
    position: absolute;
    min-width: fit-content !important;
    :hover,
    :focus,
    :active {
      background-color: var(--primary_color_2) !important;
    }

    :global(.active) {
      background-color: var(--primary_color_1) !important;
    }
  }
}
@media (max-width: 575.98px) {
  .menu_language {
    :global(.dropdown-menu) {
      left: -30px;
    }
  }
  .menu_item {
    margin-left: unset;
  }
  .collapse {
    order: 3;
  }
  .nav_dropdow {
    order: 2;
  }
}
