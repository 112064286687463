.email_input {
  background-color: gray;
  max-width: 208px;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
}

.copy_writer {
  border-top: 1px solid lightgray;
  padding-top: 2rem;
}

.rounded_circle {
  background-color: var(--secondary_color_1);
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 50%;
  padding: 7px;
}
