.font_size_12 {
  font-size: 12px;
}
.font_size_14 {
  font-size: 14px;
}
.font_size_13 {
  font-size: 13px;
}
.card_border {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
